<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-permission-group-new" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "PermissionGroups.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="permissionGroupData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="ActiveMenu" class="form-label"
                >{{
                  $t(
                    "PermissionGroups.ActiveMenu",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :isDetailIconShow="true"
                :routeLink="String.format('#/Menu/ListItem/')"
                @onChange="onChangeActiveMenu"
                :isGatewayRequest="true"
                requestUrl="/Lcdp-SummaryMenus"
              />
            </div>
            <div class="col-md-6">
              <label for="Description" class="form-label"
                >{{
                  $t(
                    "PermissionGroups.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <textarea
                type="text"
                class="form-control"
                id="Description"
                rows="1"
                :spellcheck="this.$isTextSpellCheck"
                v-model="permissionGroupData.description"
              ></textarea>
            </div>
            <div class="col-md-6">
              <label for="SpecificCode1" class="form-label"
                >{{
                  $t(
                    "PermissionGroups.SpecificCode1",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="SpecificCode1"
                v-model="permissionGroupData.specificCode1"
                :spellcheck="this.$isTextSpellCheck"
                :autocomplete="this.$isAutoComplete"
              />
            </div>
            <div class="col-md-6">
              <label for="AdminPublicIds" class="form-label"
                >{{
                  $t(
                    "PermissionGroups.GroupAdmin",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                @onMultipleSelectChange="onChangeGroupAdmin"
                :isMultiple="true"
                :isParameters="true"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryOrganizationalUnitItems?name=User'
                  )
                "
              />
            </div>
            <div class="col-md-6">
              <label for="SpecificCode2" class="form-label"
                >{{
                  $t(
                    "PermissionGroups.SpecificCode2",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="SpecificCode2"
                v-model="permissionGroupData.specificCode2"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
              />
            </div>

            <div class="col-md-6">
              <label for="WelcomeUrl" class="form-label"
                >{{
                  $t(
                    "PermissionGroups.WelcomeUrl",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="WelcomeUrl"
                v-model="permissionGroupData.welcomeUrl"
                :spellcheck="this.$isTextSpellCheck"
                :autocomplete="this.$isAutoComplete"
              />
            </div>
            <div class="col-md-6">
              <label for="SpecificCode3" class="form-label"
                >{{
                  $t(
                    "PermissionGroups.SpecificCode3",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="SpecificCode3"
                v-model="permissionGroupData.specificCode3"
                :spellcheck="this.$isTextSpellCheck"
                :autocomplete="this.$isAutoComplete"
              />
            </div>
            <div class="col-12">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="permissionGroupData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('0', 'btn-save')"
              @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
              @clear="onClear()"
              :isNewRecord="true"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "PermissionGroupNew",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      permissionGroupData: {
        isActive: true,
        isRestrictedPermissionGroup: true,
      },
      isSuccess: false,
    };
  },
  methods: {
    onClear() {
      this.permissionGroupData = {
        isActive: true,
      };
      $(".form-permission-group-new").removeClass("was-validated");
    },
    onChangeActiveMenu(selected) {
      this.permissionGroupData.activeMenuPublicId = selected.key;
      this.permissionGroupData.activeMenuName = selected.value;
    },
    onChangeGroupAdmin(ids) {
      this.permissionGroupData.RestrictedGroupOwnerPublicIds = ids;
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-permission-group-new");
      form.addClass("was-validated");
      if (!form[0].checkValidity()) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-NewRestrictedPermissionGroup", {
          ...this.permissionGroupData,
        })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/RestrictedPermissionGroup/List");
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
};
</script>
